import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: firebase.User;

  constructor(private afAuth: AngularFireAuth) {}

  setUser(user) {
    this.user = user;
  }

  getAuthenticated(): Observable<firebase.User> {
    return this.afAuth.user;
  }

  authenticateUser(email: string, password: string): Promise<any> {
    return this.afAuth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // Now sign-in using your chosen method.
        return new Promise((resolve, reject) => {
          this.afAuth.signInWithEmailAndPassword(email, password).then(
            async (userCredential) => {
              // Signed in
              var user = userCredential.user;
              this.setUser(user)

              resolve(user);
            },
            (error) => {
              reject(error);
            }
          );
        });
      })
      .catch((error) => {
        // Handle errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  isAuthenticated() {
    return this.afAuth.authState;
  }

  logout() {
    this.afAuth.signOut();
  }
}
