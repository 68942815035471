// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDPobohD95fJiWL52vszfk934cu93OGkOs",
    authDomain: "min-gf.firebaseapp.com",
    projectId: "min-gf",
    storageBucket: "min-gf.appspot.com",
    messagingSenderId: "466137299272",
    appId: "1:466137299272:web:2a60a7ac851196c07adb7d",
    measurementId: "G-N1FSRX082Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
