import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoginComponent } from './login/login.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { UploaderComponent } from './uploader/uploader.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';


@NgModule({
	declarations: [
        ValidationErrorComponent,
        LoginComponent,
        UploadTaskComponent,
        UploaderComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule
    ],
	exports: [
        ValidationErrorComponent,
        LoginComponent,
        UploadTaskComponent,
        UploaderComponent
    ]
})
export class ComponentsModule {}
