import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthService } from 'src/app/service/auth.service';
import { EmailValidator } from 'src/app/validator/email.validator';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() navigateOnSubmit: string;


  loginForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingCtrl: LoadingController,
    private authService: AuthService,
    private alertCtrl: AlertController
  ) {
    this.loginForm = formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, EmailValidator.isValid]),
      ],
      password: [
        '',
        Validators.compose([Validators.minLength(6), Validators.required]),
      ],
    });
  }

  ngOnInit() {}

  async authenticateUser() {
    if (this.loginForm.valid) {
      let loading = await this.loadingCtrl.create();
      loading.present();
      let userEmail = this.loginForm.value.email;
      let userPwd = this.loginForm.value.password;
      this.authService.authenticateUser(userEmail, userPwd).then(
        () => {
          loading.dismiss().then((user) => {
            // this.navCtrl.setRoot('overview');
            this.router.navigate(["/user"]);
          });
        },
        (error) => {
          loading.dismiss().then(async () => {
            let alert = await this.alertCtrl.create({
              message: error.message,
              buttons: [{ text: 'Ok', role: 'cancel' }],
            });
            alert.present();
          });
        }
      );
    }
  }
}
