import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Organization } from '../model/organization';

import { Storage } from '@ionic/storage-angular';
import { ReplaySubject } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService implements Resolve<any>{
  collectionName = 'organization';

  currentOrganization;

  organizationObservable: ReplaySubject<any> = new ReplaySubject(1);

  orgSubscription;

  constructor(private db: AngularFirestore, private storage: Storage) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let hostname = window.location.hostname;
    let pathId = route.paramMap.get('orgId');
    console.log("resolver", hostname)
    console.log("resolver", route.paramMap.get('orgId'))

    let organization;

    //There should only be one result
    let organizations = await this.db
        .collection(this.collectionName).ref.where('pathId', '==', pathId).get()
    organizations.forEach( org => {
      organization = org.data();
      organization.id = org.id;
    })



    // console.log("inside org page", this.organization)

    // this.orgId = this.route.snapshot.paramMap.get('orgId');
    // let hostname = window.location.hostname;
    // if(!this.orgId) {
    //   console.log("test", window.location.hostname)
    //   this.orgId = 'gf-bakkekammen-frederikssund';
    // }
    // this.orgService.setOrganization(hostname, this.orgId).then( bool => {
    //   this.orgLoaded = true;
    // })

    // //if(this.orgId != 'gf-bakkekammen-frederikssund') this.router.navigate(['welcome'])



    return organization;
  }


  setOrganization(hostname, pathId?): Promise<boolean>{
    return new Promise(async (resolve, reject) => { 
      if (hostname == 'localhost') {
        pathId = 'gf-bakkekammen-frederikssund';
      }
      return this.orgSubscription = this.db
        .collection(this.collectionName).ref.where('pathId', '==', pathId).limit(1).get().then( org => {
          console.log("org in prom", org)
          resolve(true)
        })
    })    
  }

  getOrganization(pathId) {
    return this.db
      .collection(this.collectionName, (ref) =>
        ref.where('pathId', '==', pathId).limit(1)
      )
      .valueChanges({ idField: 'id' });
  }

  getOrganizations() {
    return this.db.collection<Organization>(this.collectionName).valueChanges();
  }

  createOrganization(organization: Organization) {
    return this.db.collection(this.collectionName).add(organization);
  }
}
