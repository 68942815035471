import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OrganizationService } from './service/organization.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'gf-bakkekammen-frederikssund/tab/about',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./page/sign-in/sign-in.module').then( m => m.SignInPageModule),
  },
  {
    path: 'b',
    loadChildren: () => import('./page/backend/backend.module').then( m => m.BackendPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./page/welcome/welcome.module').then( m => m.WelcomePageModule)
  },  
  {
    path: ':orgId',
    resolve: {
      organization: OrganizationService
    },
    loadChildren: () => import('./page/organization/organization.module').then( m => m.OrganizationPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
