import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent implements OnInit {

  @Input() variable: string;
  @Input() validation_messages: any;
  @Input() validations_form: any;

  constructor() { }

  ngOnInit() {}

}
